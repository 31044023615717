@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  font-family: 'Montserrat', sans-serif !important;
}

.bookBackground {
  background-image: url(./assets/images/pattern.png);
  background-repeat: no-repeat;
  background-position: 110% 20px;
  background-color: black;
  position: relative;
  height: 100%;
}

.bookBackground h1 {
  font: normal normal normal 38px/46px Montserrat;
  color: #FFFFFF;
}

.book-p {
  font: normal normal normal 18px/25px Montserrat;
  color: #FFFFFF;
  max-width: 60%;
  margin: auto;
  padding-bottom: 15rem;
}

.hr {
  position: absolute;
  top: 5% !important;
  color: #fff;
  left: 0px;
}

.margin-bottom {
  margin-top: -7rem !important;
  margin-bottom: 15rem !important;
  position: absolute;
  width: 90% !important;
  text-align: center !important;
  left: 6%;
}

.booking-img {
  width: 100%;
}

h3 {
  font: normal normal normal 26px/31px Montserrat;
  padding-top: 1rem;
}

.description {
  font: normal normal normal 18px/25px Montserrat;
  color: #818181;
  height: 7rem;
}

.booking {
  font: normal normal normal 11px/20px Montserrat;
  color: #FFFFFF;
  text-transform: uppercase;
}

.booking-arrow {
  background-color: black !important;
  border-radius: 5px;
  width: 100%;
  border: none;
  align-items: center;
  height: 3rem;
}

footer button {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  border-radius: 5px;
  height: 45px;
}

.footer-logo {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -8rem;
}

footer {
  background-color: black;
  height: 8rem;
  align-items: center;
  font: normal normal normal 12px/15px Montserrat;
  color: #FFFFFF;
  margin-top: 40rem !important;
}

hr {
  color: grey !important;
  margin-top: -4rem !important;
  margin-bottom: 7rem !important;
}

.display {
  display: flex;
}

.footer-btn {
  display: none;
}

.footer-text {
  padding-left: 5rem;
}

.size-mobile {
  padding-inline: 0.5rem !important;
  text-align: left;
}

@media (max-width: 780px) {
  .book-p {
    max-width: 85%;
    font-size: 15px;
  }

  .max-height-mobile {
    height: 25rem;
  }

  .row {
    display: block !important;
  }

  .size-mobile {
    width: 90% !important;
    margin-top: 2rem !important;
  }

  footer {
    margin-top: 82rem !important;
    display: block;
  }

  footer img {
    max-width: 76px;
  }

  .display {
    display: none;
  }


  .footer-btn {
    display: block !important;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .rights {
    padding-top: 4rem;
    margin: auto;
  }

  .footer-text {
    padding-left: 0px !important;
  }

  .rights p {
    padding-left: -3rem;
  }

  .bookBackground img {
    max-width: 76px;
    margin-top: 1rem !important;
  }

  hr {
    margin-top: -2.5rem !important;
    margin-bottom: 2rem !important;
  }

  .margin-bottom {
    left: 12.5%;
    margin-top: -7rem !important;
  }

  h3 {
    font-size: 18px !important;
  }

  h1 {
    font-size: 18px !important;
    line-height: 30px !important;
    margin-top: 1rem !important;
  }

  .description {
    font-size: 16px !important;
    line-height: 20px;
    height: 4rem;
  }

  .bookBackground {
    background-position: -10% 0px;
  }
}

@media (min-width: 690px) and (max-width: 780px) {
  footer {
    margin-top: 120rem !important;
  }
}

@media (min-width: 600px) and (max-width: 690px) {
  footer {
    margin-top: 110rem !important;
  }
}

@media (min-width: 531px) and (max-width: 600px) {
  footer {
    margin-top: 100rem !important;
  }
}

@media (min-width: 500px) and (max-width: 530px) {
  footer {
    margin-top: 90rem !important;
  }
}

@media (min-width: 780px) {
  .height-div {
    height: 10.5rem;
  }
}
@media (min-width: 781px) and (max-width: 1000px) {
.height-div {
    height: 12rem;
  }
}